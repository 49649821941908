<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoColaboradorEquipe"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="id_colaborador_equipe"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Colaboradores Equipe</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="282"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <Filtro
            @selectedFilters="fetchColaboradorEquipe((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="statusFormated(item).color" dark>
          {{ statusFormated(item).text }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          icon
          v-can-access="283"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          icon
          v-can-access="285"
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom :name="'edit'" v-can-access="283" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="285"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalColaboradorEquipe
      :dialog="dialog"
      :labelBtn="labelBtn"
      :colaboradorEquipe="colaboradorEquipe"
      @close="refresh()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import BaseSegmento from "@/components/shared/BaseSegmento";
import IconBottom from "@/components/shared/bottons/IconBottom";
import ModalColaboradorEquipe from "./ModalColaboradorEquipe";
import Filtro from "./Filtro";
import comissao from "@/services/http/comissaoService";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";

export default {
  name: "Tabela",

  components: {
    BaseTable,
    BaseSegmento,
    RegisterBottom,
    ModalColaboradorEquipe,
    Filtro,
    IconBottom
  },

  data() {
    return {
      search: "",
      infoColaboradorEquipe: [],
      dialog: false,
      labelBtn: "Salvar",
      colaboradorEquipe: {},
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "CPF", value: "id_colaborador", align: "end" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Cod. Função", value: "cod_funcao", align: "end" },
        { text: "Função", value: "funcao" },
        { text: "Cod. Equipe", value: "id_equipe", align: "end" },
        { text: "Equipe", value: "nome_equipe" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "acoes" }
      ],
      items: [],
      loading: false,
      filters: {}
    };
  },

  methods: {
    statusFormated(colaboradorEquipe) {
      return {
        color: colaboradorEquipe.status === "S" ? "green" : "red",
        text: colaboradorEquipe.status === "S" ? "Ativo" : "Inativo"
      };
    },

    async fetchColaboradorEquipe(filters) {
      this.loading = true;
      const { data } = await comissao()
        .colaboradorEquipe()
        .show({
          status: "S",
          per_page: -1,
          ...filters
        });
      this.infoColaboradorEquipe = data.data;
      this.loading = false;
    },
    editItem(item) {
      this.colaboradorEquipe = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    refresh() {
      this.dialog = false;
      this.fetchColaboradorEquipe(this.filters);
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "deletar indicador",
          `Deseja deletar o colaborador ${item.colaborador}?`
        );

        comissao()
          .colaboradorEquipe(item.id_colaborador_equipe)
          .delete(
            {},
            {
              notification: "success",
              menssage: "Indicador deletado"
            }
          );
        this.refresh();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar indicador"
        });
      } finally {
        this.fetchColaboradorEquipe(this.filters);
      }
    }
  },

  async mounted() {
    await this.fetchColaboradorEquipe();
  }
};
</script>
