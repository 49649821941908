var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"320","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('FilterBottom',_vm._g({},on))]}}])},[_c('v-card',[_c('v-container',[_c('v-switch',{attrs:{"true-value":"S","false-value":"N","label":("" + (_vm.filters.ativo == 'S' ? 'Ativo' : 'Inativo'))},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.ativo),callback:function ($$v) {_vm.$set(_vm.filters, "ativo", $$v)},expression:"filters.ativo"}}),_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-file-table-box-multiple-outline","clearable":"","single-line":"","label":"Segmento","item-text":"descricao","item-value":"id_band","service":"sistemaService.tipoBandeiras","isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_band),callback:function ($$v) {_vm.$set(_vm.filters, "id_band", $$v)},expression:"filters.id_band"}}),_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-domain","clearable":"","single-line":"","label":"Empresas","item-text":"apelido","item-value":"id_empresa","service":"sistemaService.empresa","filters":{
          id_band: _vm.filters.id_band,
          ativo: 'S'
        },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_empresa),callback:function ($$v) {_vm.$set(_vm.filters, "id_empresa", $$v)},expression:"filters.id_empresa"}}),_c('BaseFilter',{attrs:{"label":"Função","service":"sistemaService.funcoes","item-text":"descricao","item-value":"id_funcao","prepend-inner-icon":"mdi-account-tie","clearable":"","single-line":"","filters":{
          id_empresa: _vm.filters.id_empresa,
          ativo: 'S'
        }},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_funcao),callback:function ($$v) {_vm.$set(_vm.filters, "id_funcao", $$v)},expression:"filters.id_funcao"}}),_c('BaseFilter',{attrs:{"label":"Equipe","service":"comissaoService.tipoEquipe","item-text":"nome_equipe","item-value":"id_equipe","prepend-inner-icon":"mdi-account-multiple","clearable":"","single-line":"","filters":{
          id_empresa: _vm.filters.id_empresa,
          ativo: 'S'
        }},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_equipe),callback:function ($$v) {_vm.$set(_vm.filters, "id_equipe", $$v)},expression:"filters.id_equipe"}}),_c('BaseFilter',{attrs:{"label":"Colaborador","service":"sistemaService.colaboradorEmpresa","prepend-inner-icon":"mdi-account","item-text":"colaborador","item-value":"id_colaborador","clearable":"","single-line":"","filters":{
          id_band: _vm.filters.id_band,
          id_funcao: _vm.filters.id_funcao,
          id_empresa: _vm.filters.id_empresa,
          ativo: 'S'
        },"hasCode":true,"multiple":false},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_colaborador),callback:function ($$v) {_vm.$set(_vm.filters, "id_colaborador", $$v)},expression:"filters.id_colaborador"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }