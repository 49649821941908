<template>
  <v-dialog v-model="dialog" persistent max-width="50%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Colaborador Equipe"
                : "Cadastrar Colaborador Equipe"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row align="center">
            <v-col xl="6" lg="4" md="6" sm="12" cols="12">
              <BaseSelect
                prepend-inner-icon="mdi-file-table-box-multiple-outline"
                clearable
                single-line
                label="Segmento"
                item-text="descricao"
                item-value="id_band"
                service="sistemaService.tipoBandeiras"
                v-model="formColaboradorEquipe.id_band"
                :isCompany="true"
              />
            </v-col>
            <v-col xl="6" lg="4" md="6" sm="12" cols="12">
              <BaseSelect
                label="Empresa"
                v-model="formColaboradorEquipe.id_empresa"
                service="sistemaService.empresa"
                item-text="apelido"
                item-value="id_empresa"
                prepend-inner-icon="mdi-domain"
                :rules="[rules.required]"
                clearable
                :isCompany="true"
                :multiple="false"
                :filters="{
                  id_band: formColaboradorEquipe.id_band
                }"
                @change="formColaboradorEquipe.idEmpresa = $event"
              />
            </v-col>
            <v-col xl="6" lg="4" md="6" sm="12" cols="12">
              <BaseSelect
                label="Colaborador"
                v-model="formColaboradorEquipe.id_colaborador"
                service="sistemaService.colaboradorEmpresa"
                prepend-inner-icon="mdi-account"
                item-text="colaborador"
                item-value="id_colaborador"
                :rules="[rules.required]"
                clearable
                :multiple="false"
                :disabled="!formColaboradorEquipe.idEmpresa"
              />
            </v-col>

            <v-col xl="6" lg="4" md="6" sm="12" cols="12">
              <BaseSelect
                label="Equipe"
                item-text="nome_equipe"
                item-value="id_equipe"
                service="comissaoService.colaboradorEquipe"
                clearable
                v-model="formColaboradorEquipe.id_equipe"
                prepend-inner-icon="mdi-domain"
                :disabled="!formColaboradorEquipe.idEmpresa"
              />
            </v-col>
            <v-col xl="6" lg="4" md="6" sm="12" cols="12">
              <BaseSelect
                label="Nivel da Função"
                item-text="funcao_nivel"
                v-model="formColaboradorEquipe.id_funcao"
                item-value="id_funcao"
                service="sistemaService.funcoes"
                clearable
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseSelect from "@/components/shared/BaseSelectService";
import rules from "@/mixins/inputRules";
import comissao from "@/services/http/comissaoService";
export default {
  name: "ModalColaboradorEquipe",
  mixins: [rules],

  components: {
    BaseSelect
  },

  props: {
    colaboradorEquipe: {
      type: Object,
      default: () => ({})
    },
    labelBtn: {
      type: String
    },
    dialog: {
      type: Boolean
    }
  },

  data() {
    return {
      formIsValid: false,
      formColaboradorEquipe: {},
      items: []
    };
  },
  watch: {
    colaboradorEquipe() {
      if (this.colaboradorEquipe) {
        this.formColaboradorEquipe = this.colaboradorEquipe;
      }
    }
  },

  methods: {
    async send() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .colaboradorEquipe()
          .store(this.formColaboradorEquipe, {
            notification: true,
            message: "Colaborador Equipe cadastrado com sucesso!"
          }),
          this.close();
      }
    },
    async put() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await comissao()
          .colaboradorEquipe(this.formColaboradorEquipe.id_colaborador_equipe)
          .update(this.formColaboradorEquipe, {
            notification: true,
            message: "Colaborador Equipe editado com sucesso!"
          }),
          this.$notify({
            type: "success",
            text: "Colaborador Equipe editado"
          });

        this.close();
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>
