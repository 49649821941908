<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>
    <v-card>
      <v-container>
        <v-switch
          v-model="filters.ativo"
          true-value="S"
          false-value="N"
          :label="`${filters.ativo == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        ></v-switch>
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Segmento"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          v-model="filters.id_band"
          :isCompany="true"
          @change="handleFilter()"
        />
        <BaseFilter
          prepend-inner-icon="mdi-domain"
          clearable
          single-line
          label="Empresas"
          item-text="apelido"
          item-value="id_empresa"
          service="sistemaService.empresa"
          :filters="{
            id_band: filters.id_band,
            ativo: 'S'
          }"
          v-model="filters.id_empresa"
          :isCompany="true"
          @change="handleFilter()"
        />
        <BaseFilter
          label="Função"
          service="sistemaService.funcoes"
          v-model="filters.id_funcao"
          item-text="descricao"
          item-value="id_funcao"
          prepend-inner-icon="mdi-account-tie"
          clearable
          single-line
          :filters="{
            id_empresa: filters.id_empresa,
            ativo: 'S'
          }"
          @change="handleFilter()"
        />

        <BaseFilter
          label="Equipe"
          service="comissaoService.tipoEquipe"
          v-model="filters.id_equipe"
          item-text="nome_equipe"
          item-value="id_equipe"
          prepend-inner-icon="mdi-account-multiple"
          clearable
          single-line
          :filters="{
            id_empresa: filters.id_empresa,
            ativo: 'S'
          }"
          @change="handleFilter()"
        />
        <BaseFilter
          label="Colaborador"
          v-model="filters.id_colaborador"
          service="sistemaService.colaboradorEmpresa"
          prepend-inner-icon="mdi-account"
          item-text="colaborador"
          item-value="id_colaborador"
          clearable
          single-line
          :filters="{
            id_band: filters.id_band,
            id_funcao: filters.id_funcao,
            id_empresa: filters.id_empresa,
            ativo: 'S'
          }"
          :hasCode="true"
          :multiple="false"
          @change="handleFilter()"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>
<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import FilterBottom from "@/components/shared/bottons/FilterBottom";

export default {
  name: "Filtro",

  components: {
    BaseFilter,
    FilterBottom
  },

  data() {
    return {
      filters: {
        ativo: "S"
      }
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
